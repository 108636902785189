import React, { useState, useEffect } from "react";
import { getUser } from "../services/authService";
import {
  countAllPlaygrounds,
  countAllPlaygroundsToModerate,
  numberPlaygroundsByCity,
  numberPlaygroundsByState,
  getAllState,
  getAllCity,
} from "../services/playgroundService";
import { countAllEvents } from "../services/eventService";
import { countAllUsers } from "../services/authService";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faGlobeEurope,
  faCity,
  faRunning,
  faCalendarAlt,
  faBasketballBall,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";
import "../assets/style/globalStyle.scss";

const AdminPage = () => {
  const todaysDate = new Date();
  const todaysDateFormatted = new Intl.DateTimeFormat("fr-FR", {
    dateStyle: "full",
  }).format(Date.parse(todaysDate));

  const [countPlaygroundsToModerate, setCountPlaygroundsToModerate] = useState(
    []
  );
  const [countPlaygrounds, setCountPlaygrounds] = useState([]);
  const [countEvents, setCountEvents] = useState([]);
  const [countUsers, setCountUsers] = useState([]);
  const [allCity, setAllCity] = useState([]);
  const [allState, setAllState] = useState([]);
  const [citySelected, setCitySelected] = useState([""]);
  const [stateSelected, setStateSelected] = useState([""]);
  const [numPlaygroundCity, setNumPlaygroundCity] = useState(0);
  const [numPlaygroundState, setNumPlaygroundState] = useState(0);

  const countAllValidatedPlaygrounds = async () => {
    const data = await countAllPlaygrounds();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>OOOOOOOOHHHHHHHH DAMN</h1>
        </div>
      );
    }

    setCountPlaygrounds(data);
  };

  const countAllNotValidatedPlaygrounds = async () => {
    const data = await countAllPlaygroundsToModerate();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>OOOOOOOOHHHHHHHH DAMN</h1>
        </div>
      );
    }

    setCountPlaygroundsToModerate(data);
  };

  const countAllUsersApp = async () => {
    const data = await countAllUsers();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>OOOOOOOOHHHHHHHH DAMN</h1>
        </div>
      );
    }

    setCountUsers(data);
  };

  const countAllValidatedEvents = async () => {
    const data = await countAllEvents();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>Pas d'évènements en cours</h1>
        </div>
      );
    }

    setCountEvents(data);
  };

  const getAllCityToSelect = async () => {
    const data = await getAllCity();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>Problème de récupération des villes</h1>
        </div>
      );
    }

    setAllCity(data);
  };

  const getAllStateToSelect = async () => {
    const data = await getAllState();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>Problème de récupération des régions</h1>
        </div>
      );
    }

    setAllState(data);
  };

  useEffect(() => {
    countAllValidatedPlaygrounds();
    countAllNotValidatedPlaygrounds();
    countAllValidatedEvents();
    countAllUsersApp();
    getAllCityToSelect();
    getAllStateToSelect();
  }, []);

  return (
    <div className="column is-12 is-12-mobile">
      <section className="hero">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">Bienvenue sur le CRM, {getUser().name}</h1>
            <h2 className="subtitle">
              {todaysDateFormatted.charAt(0).toUpperCase() +
                todaysDateFormatted.slice(1)}
            </h2>
          </div>
        </div>
      </section>

      <section className="info-tiles">
        <div className="tile is-ancestor has-text-centered">
          <div className="tile is-parent">
            <article className="tile is-child box">
              <Icon icon={faCheckDouble} size="3x" color="rgba(242,99,54,1)" />
              <p className="title" style={{ marginTop: "10px" }}>
                {countPlaygrounds.countPlaygrounds === undefined
                  ? 0
                  : countPlaygrounds.countPlaygrounds}
              </p>
              <p className="subtitle">Terrains validés</p>
            </article>
          </div>
          <div className="tile is-parent">
            <article className="tile is-child box">
              <Icon
                icon={faBasketballBall}
                size="3x"
                color="rgba(242,99,54,1)"
              />
              <p className="title" style={{ marginTop: "10px" }}>
                {countPlaygroundsToModerate.countPlaygroundsToModerate ===
                undefined
                  ? 0
                  : countPlaygroundsToModerate.countPlaygroundsToModerate}
              </p>
              <p className="subtitle">Terrains à modérer</p>
            </article>
          </div>
          <div className="tile is-parent">
            <article className="tile is-child box">
              <Icon icon={faRunning} size="3x" color="rgba(242,99,54,1)" />
              <p className="title" style={{ marginTop: "10px" }}>
                {countUsers.countAllUsers === undefined
                  ? 0
                  : countUsers.countAllUsers}
              </p>
              <p className="subtitle">Utilisateurs</p>
            </article>
          </div>
          <div className="tile is-parent">
            <article className="tile is-child box">
              <Icon icon={faCalendarAlt} size="3x" color="rgba(242,99,54,1)" />
              <p className="title" style={{ marginTop: "10px" }}>
                {countEvents.countEvents === undefined
                  ? 0
                  : countEvents.countEvents}
              </p>
              <p className="subtitle">Évènements</p>
            </article>
          </div>
        </div>

        <div className="tile is-ancestor has-text-centered">
          <div className="tile is-parent">
            <article className="tile is-child box">
              <Icon icon={faCity} size="3x" color="rgba(242,99,54,1)" />
              <p className="subtitle" style={{ marginTop: "10px" }}>
                Nombre de playground par ville
              </p>
              <div
                className="select"
                value={citySelected}
                onChange={async (e) => {
                  setCitySelected(e.target.value);
                  const data = await numberPlaygroundsByCity(e.target.value);

                  if (!data) {
                    return (
                      <div
                        className="container is-fullhd"
                        style={{ width: "880px" }}
                      >
                        <h1>
                          Problème de récupération du nombre de playground par
                          ville
                        </h1>
                      </div>
                    );
                  }
                  setNumPlaygroundCity(data);
                }}
              >
                <select>
                  <option>Sélectionner une ville</option>
                  {allCity.length !== 0 &&
                    allCity.playgroundCity.map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                </select>
              </div>
              {numPlaygroundCity === 0 ? null : (
                <p className="title" style={{ marginTop: "10px" }}>
                  {numPlaygroundCity.countPlaygrounds}
                </p>
              )}
            </article>
          </div>
          <div className="tile is-parent">
            <article className="tile is-child box">
              <Icon icon={faGlobeEurope} size="3x" color="rgba(242,99,54,1)" />
              <p className="subtitle" style={{ marginTop: "10px" }}>
                Nombre de playground par région
              </p>
              <div
                className="select"
                value={stateSelected}
                onChange={async (e) => {
                  setStateSelected(e.target.value);
                  const data = await numberPlaygroundsByState(e.target.value);

                  if (!data) {
                    return (
                      <div
                        className="container is-fullhd"
                        style={{ width: "880px" }}
                      >
                        <h1>
                          Problème de récupération du nombre de playground par
                          région
                        </h1>
                      </div>
                    );
                  }
                  setNumPlaygroundState(data);
                }}
              >
                <select>
                  <option>Sélectionner une région</option>
                  {allState.length !== 0 &&
                    allState.playgroundState.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                </select>
              </div>
              {numPlaygroundState === 0 ? null : (
                <p className="title" style={{ marginTop: "10px" }}>
                  {numPlaygroundState.countPlaygrounds}
                </p>
              )}
            </article>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AdminPage;
