import trackerApi from "../api/tracker";
import { navigate } from "gatsby";

export const addEvent = async ({
  endDate,
  startDate,
  time,
  timeEnd,
  streetName,
  streetNumber,
  name,
  city,
  zipCode,
  floor,
  type,
  limitedByPoint,
  limitPoint,
  matchDuration,
  description,
  payant,
  urlTypeForm,
  ageOfPlayer,
  listOfTeam,
  listOfSponsor,
  organizingSociety,
  organizingPartner,
  ticketingLink,
  discountCode,
  spectators,
  players
}) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const event = await trackerApi.post(
      "/addEvent",
      {
        token,
        endDate,
        startDate,
        time,
        timeEnd,
        streetName,
        streetNumber,
        name,
        city,
        zipCode,
        floor,
        type,
        limitedByPoint,
        limitPoint,
        matchDuration,
        description,
        payant,
        urlTypeForm,
        ageOfPlayer,
        listOfTeam,
        listOfSponsor,
        organizingSociety,
        organizingPartner,
        ticketingLink,
        discountCode,
        spectators,
        players
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return event.data;
  } catch (error) {
    console.log("error can't create an event : ", error);
  }
};

export const getAllEvents = async () => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const response = await trackerApi.get("/getEvent", {
      headers: { Authorization: `Bearer ${token}` },
      params: { token },
    });
    return response.data;
  } catch (error) {
    console.log("error to get all events :", error);
  }
};

export const getAllEventsUpToDate = async () => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const response = await trackerApi.get("/getEventUpToDate", {
      headers: { Authorization: `Bearer ${token}` },
      params: { token },
    });
    return response.data;
  } catch (error) {
    console.log("error to get all events up to date :", error);
  }
};

export const countAllEvents = async () => {
  try {
    const response = await trackerApi.get("/countAllEvents");
    return response.data;
  } catch (error) {
    console.log("error to countAllEvents :", error);
  }
};

export const getEventById = async (id) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const event = await trackerApi.get(`/getEventById/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { token, id },
    });
    return event.data;
  } catch (error) {
    console.log("error can't get an event : ", error);
  }
};

export const updateEventById = async ({
  eventId,
  endDate,
  time,
  timeEnd,
  startDate,
  streetName,
  streetNumber,
  name,
  city,
  zipCode,
  floor,
  type,
  limitedByPoint,
  limitPoint,
  matchDuration,
  description,
  payant,
  ticketingLink,
  discountCode,
  urlTypeForm,
  ageOfPlayer,
  listOfTeam,
  listOfSponsor,
  organizingSociety,
  organizingPartner,
  spectators,
  players
}) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const eventUpdated = await trackerApi.post(
      "/updateEvent",
      {
        token,
        eventId,
        endDate,
        time,
        timeEnd,
        startDate,
        streetName,
        streetNumber,
        name,
        city,
        zipCode,
        floor,
        type,
        limitedByPoint,
        limitPoint,
        matchDuration,
        description,
        payant,
        ticketingLink,
        discountCode,
        urlTypeForm,
        ageOfPlayer,
        listOfTeam,
        listOfSponsor,
        organizingSociety,
        organizingPartner,
        spectators,
        players
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );


    if (eventUpdated.data.error === "address incorrect") {
      return eventUpdated.data
    }

  } catch (error) {
    console.log("ERROR can't update an event");
  }
};

export const deleteEvent = async ({ eventId }) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    ////////////////////// EVENT TRANSACTION ///////////////////////////
    await trackerApi.post(
      "/deleteEventTransaction",
      {
        token,
        eventId
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    //////////////////////// END TRANSACTION ///////////////////////////////////////
    await trackerApi.post(
      "/deleteEvent",
      {
        eventId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    navigate("/app/allEvents");
  } catch (error) {
    console.log("ERROR can't delete event");
  }
};

export const addEventImg = async ({
  imgEventsBase64,
  eventId,
  pictureChoice,
}) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const addImageSelected = await trackerApi.post(
      "/imgEvent",
      {
        token,
        imgEventsBase64,
        eventId,
        pictureChoice,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("addImageSelected :", addImageSelected);
    return addImageSelected.status
  } catch (error) {
    console.log("ERROR can't add image : ", pictureChoice);
  }
};

export const addVoteMVP = async ({
  linkedEvent,
  selectedTeam,
  listOfPlayer,
  voteTime,
}) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const event = await trackerApi.post(
      "/addVoteMVP/",
      {
        token,
        linkedEvent,
        selectedTeam,
        listOfPlayer,
        voteTime,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return event.data;
  } catch (error) {
    console.log("error can't create a vote : ", error);
  }
};

export const passVoteToFalse = async ({
  eventId,
}) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const event = await trackerApi.post(
      "/passVoteToFalse/",
      {
        token,
        eventId,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return event.data;
  } catch (error) {
    console.log("error can't pass to false a vote : ", error);
  }
};
