import trackerApi from "../api/tracker";

import { navigate } from "gatsby";

export const isBrowser = () => typeof window !== "undefined";

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("gatsbyUser")
    ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
    : {};

const setUser = (user) =>
  window.localStorage.setItem("gatsbyUser", JSON.stringify(user));

export const handleLogin = async ({ email, password }) => {
  try {
    let response = await trackerApi.post("/signinModerate", {
      email,
      password,
    });
    let token = response.data.token;
    setUser({
      username: `john`,
      name: `Admin`,
      email: email,
      token: token,
    });
    navigate(`/app/adminPage`);
  } catch (err) {
    console.log("erreur de login : ", err);
  }
  return false;
};

export const countAllUsers = async () => {
  try {
    const response = await trackerApi.get("/countAllUsers");
    return response.data;
  } catch (error) {
    console.log("error countAllUsers :", error);
  }
};

export const getEmailById = async (userId) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const response = await trackerApi.get("/getEmailById", {
      headers: { Authorization: `Bearer ${token}` },
      params: { token, userId },
    });
    return response.data;
  } catch (error) {
    console.log("error get email :", error);
  }
};

export const getAllEmailFromUserApple = async () => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const response = await trackerApi.get("/getAllEmailFromUserApple", {
      headers: { Authorization: `Bearer ${token}` },
      params: { token },
    });
    return response.data;
  } catch (error) {
    console.log("error getAllEmailFromUserApple :", error);
  }
};
export const getAllEmailFromUserFacebook = async () => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const response = await trackerApi.get("/getAllEmailFromUserFacebook", {
      headers: { Authorization: `Bearer ${token}` },
      params: { token },
    });
    return response.data;
  } catch (error) {
    console.log("error getAllEmailFromUserFacebook :", error);
  }
};

export const getAllEmailFromUserBNC = async () => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const response = await trackerApi.get("/getAllEmailFromUserBNC", {
      headers: { Authorization: `Bearer ${token}` },
      params: { token },
    });
    return response.data;
  } catch (error) {
    console.log("error getAllEmailFromUserBNC :", error);
  }
};

export const addUserNewsletter = async ({ email }) => {
  try {
    const User = await trackerApi.post(
      "/addUserNewsletter/",
      {
        email
      },
    );
    return User.data;
  } catch (error) {
    console.log("error can't create a User : ", error);
  }
};

export const isLoggedIn = () => {
  const user = getUser();

  return !!user.username;
};

export const logout = (callback) => {
  setUser({});
  callback();
};
